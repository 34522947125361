import client from "@/api/client"
import { ConfirmWalletRequest, ConnectWalletRequest } from "@/api/sdk"
import { cdnPublicFolderUrl } from "@/constants"
import { useToast } from "@/hooks"
import { useClearUrlQuery } from "@/hooks/useQuery"
import { cn } from "@/lib/utils"
import { useSignInStore } from "@/stores"
import { warpNameLength } from "@/utils/parser"
import { useWalletMultiButton } from "@solana/wallet-adapter-base-ui"
import { useWallet } from "@solana/wallet-adapter-react"
import { useWalletModal } from "@solana/wallet-adapter-react-ui"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import base58 from "bs58"
import Image from "next/image"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useEffect, useState } from "react"
import ClientOnly from "../ClientOnly"
import IconButton from "../IconButton"
import Input from "../Input"
import Modal from "../Modal"
import { useWalletOwned } from "../Setting/Profile/WalletInfo"
import TextCopy from "../TextCopy"
import { TickIcon } from "../shared/icons"

const LinkWalletAccountModal = () => {
  const router = useCustomRouter()
  const clearUrl = useClearUrlQuery()
  const { signInModal, setSignInModal } = useSignInStore()
  const qc = useQueryClient()
  const toast = useToast()

  const [mode, setMode] = useState<
    "connect-wallet" | "no-wallet" | "link-account" | "wallet-already-linked" | "success"
  >("connect-wallet")
  const [isAboutToSignInWithWallet, setAboutToSignInWithWallet] = useState(false)
  const [isSigningInWithWallet, setSigningInWithWallet] = useState(false)

  const isOpen = (router.query.linkWalletAccount === "true" || signInModal.linkWallet) ?? false
  const wallet = useWallet()
  const walletButton = useWalletMultiButton({
    onSelectWallet: config => console.log("onSelectWallet", config),
  })
  const walletModal = useWalletModal()

  const { data: walletOwned } = useWalletOwned()

  const onClose = () => {
    document.body.style.overflow = "unset"
    setSignInModal({ linkWallet: false })
    setMode("connect-wallet")
    if (router.query.linkWalletAccount === "true") {
      clearUrl("linkWalletAccount")
    }
  }

  const { mutateAsync: mutateLinkWallet } = useMutation({
    mutationFn: (data: ConnectWalletRequest) => client.api.walletControllerConnectWallet(data).then(res => res.data),
    onError: (error: any) => {
      if (error.statusCode === 400 && error.message === "Wallet already connected to another account") {
        setMode("wallet-already-linked")
        return
      }

      toast({
        title: "Error",
        status: "error",
        message: [error.message ?? "Something went wrong, please try again later"],
      })
    },
  })

  const { mutate: mutateLinkWalletConfirm } = useMutation({
    mutationFn: (data: ConfirmWalletRequest) =>
      client.api.walletControllerConfirmConnectWallet(data).then(res => res.data),
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ["wallet-owned"],
      })
      setMode("success")
    },
    onError: error => {
      console.error(error)
    },
  })

  const handleSwitchWallet = async () => {
    walletModal.setVisible(true)
    // if (wallet.connected) {
    //   setMode("connect-wallet")
    // }

    return setAboutToSignInWithWallet(true)
  }

  const submit = async () => {
    if (mode === "connect-wallet") {
      if (!wallet.connected) {
        walletModal.setVisible(true)
      }

      if (!wallet.publicKey) {
        return setAboutToSignInWithWallet(true)
      }

      if (walletOwned?.wallets.find(item => item.id === wallet.publicKey?.toString())) {
        onClose()
        return
      }

      setMode("link-account")
      return
    }

    if (mode === "link-account" || mode === "wallet-already-linked") {
      if (!wallet.connected || !wallet.publicKey || !wallet.signMessage) return

      if (walletOwned?.wallets.find(item => item.id === wallet.publicKey?.toString())) {
        setMode("wallet-already-linked")

        return
      }

      try {
        const result = await mutateLinkWallet({
          address: wallet.publicKey?.toString() ?? "",
        })

        const data = new TextEncoder().encode(result.message)
        const signature = await wallet.signMessage(data)
        const serializedSignature = base58.encode(signature)

        mutateLinkWalletConfirm({
          signature: serializedSignature,
          address: result.address,
          timestamp: result.timestamp,
          provider: "Solana",
        })
      } catch (error) {
        console.error(error)
      }

      return
    }
  }

  useEffect(() => {
    if (!walletModal.visible && isAboutToSignInWithWallet && !wallet.connecting) {
      setAboutToSignInWithWallet(false)
    }
  }, [wallet.connecting, walletModal.visible, isAboutToSignInWithWallet])

  useEffect(() => {
    if (wallet.connected && mode === "connect-wallet" && isOpen) {
      if (walletOwned?.wallets.find(item => item.id === wallet.publicKey?.toString())) {
        setMode("wallet-already-linked")
      } else {
        setMode("link-account")
      }
    }
  }, [wallet.connected, mode, walletOwned?.wallets, wallet.publicKey, isOpen])

  useEffect(() => {
    if (!wallet.connected && !wallet.publicKey) {
      setMode("connect-wallet")
    }
  }, [wallet.connected, wallet.publicKey])

  const renderMode = () => {
    switch (mode) {
      case "no-wallet":
        return (
          <div>
            <p className="text-atherGray-300 mb-4">
              To complete this action, you will need to provide an Authentication Signature by installing a Browser that
              supports your wallet extension.
            </p>
            <p className="text-atherGray-300 mb-6">Note: We recommend you to use Chrome</p>
            <IconButton className="w-full py-2.5 rounded-xl" onClick={onClose}>
              Thank, I understand
            </IconButton>
          </div>
        )

      case "link-account":
        return (
          <div>
            <p className="text-atherGray-300 mb-6">This Solana wallet has not been linked to your GAIA account yet!</p>
            <div className="mb-6">
              <Input
                value={warpNameLength(wallet?.publicKey?.toString() ?? "")}
                className="rounded-lg bg-atherGray-850"
                disabled
                rightIcon={<TextCopy value={wallet.publicKey?.toString() ?? ""} />}
              />
            </div>
            <IconButton onClick={() => submit()} className="w-full py-2.5 rounded-xl">
              Link wallet
            </IconButton>
            {walletButton.walletName && !isAboutToSignInWithWallet && !isSigningInWithWallet && (
              <div className="flex mt-2 items-center justify-center space-x-4">
                <button
                  type="button"
                  className="text-xs text-center text-red-500 min-w-[5rem] underline cursor-pointer"
                  onClick={async () => {
                    await wallet.disconnect()
                    setMode("connect-wallet")
                  }}
                >
                  Disconnect
                </button>
              </div>
            )}
          </div>
        )

      case "wallet-already-linked":
        return (
          <div>
            <p className="text-atherGray-300 mb-6">
              This Solana wallet is already linked to{" "}
              {walletOwned?.wallets.find(item => item.id === wallet.publicKey?.toString()) ? "your" : "another"} GAIA
              account!
            </p>
            <div className="mb-6">
              <Input
                value={warpNameLength(wallet?.publicKey?.toString() ?? "")}
                className="rounded-lg bg-atherGray-850"
                disabled
                rightIcon={<TextCopy value={wallet.publicKey?.toString() ?? ""} />}
              />
            </div>
            <p className="text-atherGray-300 mb-6">
              Please interact directly on the wallet extension to choose another wallet address to continue
            </p>
            {/* <div className="flex items-center mb-2">
              <p className="text-atherGray-300 mr-1">CRYPTO-WALLET</p>
              <Tooltip
                trigger={
                  <div>
                    <InfoIcon className="text-atherGray-600" width={14} height={14} />
                  </div>
                }
              >
                <p className="font-semibold text-sm mb-1">CRYPTO-WALLEt</p>
                <p className="text-atherGray-300 text-xs">
                  Wallets are used to send, receive, and store digital assets like Solana. Wallets come in many forms.
                  For more information about wallets, see this explanation.
                </p>
              </Tooltip>
            </div> */}
            <ClientOnly>
              <IconButton
                onClick={() => handleSwitchWallet()}
                colorScheme="secondary"
                className={cn("w-full py-3 rounded-xl bg-atherGray-850 justify-start overflow-hidden", {
                  "justify-center": isAboutToSignInWithWallet || isSigningInWithWallet,
                })}
                isLoading={isAboutToSignInWithWallet || isSigningInWithWallet}
              >
                <img src={`${cdnPublicFolderUrl}/solana.svg`} alt="Wallet Logo" width={32} height={32} />
                <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  <p className="whitespace-nowrap">Solana Wallet</p>
                </div>
              </IconButton>
              {walletButton.walletName && !isAboutToSignInWithWallet && !isSigningInWithWallet && (
                <div className="flex mt-2 items-center justify-center space-x-4">
                  <button
                    type="button"
                    className="text-xs text-center text-atherGray-100 min-w-[5rem] underline cursor-pointer"
                    onClick={handleSwitchWallet}
                  >
                    Switch wallet
                  </button>
                  <button
                    type="button"
                    className="text-xs text-center text-red-500 min-w-[5rem] underline cursor-pointer"
                    onClick={async () => {
                      await wallet.disconnect()
                      setMode("connect-wallet")
                    }}
                  >
                    Disconnect
                  </button>
                </div>
              )}
            </ClientOnly>
          </div>
        )

      case "success":
        return (
          <div className="flex flex-col items-center">
            <div className="rounded-full w-10 h-10 mb-6 bg-atherPurple-500 flex items-center justify-center">
              <TickIcon width={20} height={20} />
            </div>
            <h3 className="text-xl font-semibold text-center mb-4">Solana Wallet is linked to your GAIA account</h3>
            <h3 className="text-xl font-semibold text-center">Stay tuned for exclusive updates & rewards!</h3>
          </div>
        )

      default:
        return (
          <div>
            <p className="text-atherGray-300 mb-8">
              You can link multiple Solana wallets to your GAIA account. Please connect your Solana wallet to the
              browser extension first and then link to your GAIA account.
            </p>
            {/* <div className="flex items-center mb-2">
              <p className="text-atherGray-300 mr-1">Crypto-wallet</p>
              <Tooltip
                trigger={
                  <div>
                    <InfoIcon className="text-atherGray-600" width={14} height={14} />
                  </div>
                }
              >
                <p className="font-semibold text-sm mb-1">CRYPTO-WALLET</p>
                <p className="text-atherGray-300 text-xs">
                  Wallets are used to send, receive, and store digital assets like Solana. Wallets come in many forms.
                  For more information about wallets, see this explanation.
                </p>
              </Tooltip>
            </div> */}
            <ClientOnly>
              <IconButton
                onClick={() => submit()}
                colorScheme="secondary"
                className={cn("w-full py-3 rounded-xl bg-atherGray-850 justify-start overflow-hidden", {
                  "justify-center": isAboutToSignInWithWallet || isSigningInWithWallet,
                })}
                isLoading={isAboutToSignInWithWallet || isSigningInWithWallet}
              >
                <Image
                  src={walletButton.walletIcon || `${cdnPublicFolderUrl}/solana.svg`}
                  alt="Wallet Logo"
                  width={32}
                  height={32}
                />
                <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  <p className="truncate">
                    {walletButton.walletName
                      ? `${walletButton.walletName} (${
                          walletButton?.publicKey
                            ? warpNameLength(walletButton?.publicKey?.toString(), 4, 6)
                            : "Not connected"
                        })`
                      : "Solana Wallet"}
                    <span className="text-green-500">
                      {walletOwned?.wallets.find(item => item.id === walletButton?.publicKey?.toString()) &&
                        ` (Linked)`}
                    </span>
                  </p>
                </div>
              </IconButton>
              {walletButton.walletName && !isAboutToSignInWithWallet && !isSigningInWithWallet && (
                <div className="flex mt-2 items-center justify-center space-x-4">
                  <button
                    type="button"
                    className="text-xs text-center text-atherGray-100 min-w-[5rem] underline cursor-pointer"
                    onClick={handleSwitchWallet}
                  >
                    Switch wallet
                  </button>
                  <button
                    type="button"
                    className="text-xs text-center text-red-500 min-w-[5rem] underline cursor-pointer"
                    onClick={async () => {
                      await wallet.disconnect()
                    }}
                  >
                    Disconnect
                  </button>
                </div>
              )}
            </ClientOnly>
          </div>
        )
    }
  }

  return (
    <Modal
      key={mode}
      title={
        mode === "connect-wallet"
          ? "Connect your Solana wallet"
          : mode === "no-wallet"
            ? "No wallet extension detected"
            : mode === "link-account"
              ? "Link wallet to GAIA account!"
              : mode === "wallet-already-linked"
                ? "Wallet already in use"
                : undefined
      }
      className="overflow-visible max-w-md"
      isOpen={isOpen}
      onClose={onClose}
    >
      {renderMode()}
    </Modal>
  )
}

export default LinkWalletAccountModal
